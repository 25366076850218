@font-face {
    font-family: "Montserrat-Regular";
    src: url("/assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-family: "Montserrat-Bold";
    src: url("/assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
    font-display: block;
}



@font-face {
    font-family: "DynaPuff-Regular";
    src: url("/assets/fonts/DynaPuff/DynaPuff-Regular.ttf") format("truetype");
    font-display: block;
}

// @font-face {
//     font-family: "DynaPuff-Medium";
//     src: url("/assets/fonts/DynaPuff/DynaPuff-Medium.ttf") format("truetype");
//     font-display: block;
// }

// @font-face {
//     font-family: "DynaPuff-SemiBold";
//     src: url("/assets/fonts/DynaPuff/DynaPuff-SemiBold.ttf") format("truetype");
//     font-display: block;
// }

// @font-face {
//     font-family: "DynaPuff-Bold";
//     src: url("/assets/fonts/DynaPuff/DynaPuff-Bold.ttf") format("truetype");
//     font-display: block;
// }
