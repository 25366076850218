@mixin a($e) { @extend [data-a~="#{$e}"]; }
@mixin d($e) { @extend [data-d~="#{$e}"]; }
@mixin g($e) { @extend [data-g~="#{$e}"]; }





[data-p~="1__1"] {
	background: white !important;
	width: initial !important;
	height: 2px !important;
    top: initial !important;
	right: 0 !important;
	left: 0 !important;

    span {
        background: var(--color-1st) !important;
    }
}

[data-p~="2__1"] {
	&:checked {
		~ [data-p~="2__2"] {
			border-color: var(--color-1st);

			[data-p~="2__3"] {
				color: var(--color-1st);
			}
		}
	}
}

[data-p~="3__1"] {
	[data-p~="3__2"] {
		&:nth-child(4n+1) { [data-p~="3__3"] { background-color: #dcc891 } }
		&:nth-child(4n+2) { [data-p~="3__3"] { background-color: #a8bdd6 } }
		&:nth-child(4n+3) { [data-p~="3__3"] { background-color: #e39d92 } }
		&:nth-child(4n+4) { [data-p~="3__3"] { background-color: #a6888b } }
	}
}

[data-p~="4__1"] {
	[data-p~="4__2"] {
		&:nth-child(8n+1) { [data-p~="4__3"] { background-color: #dcc891 } }
		&:nth-child(8n+2) { [data-p~="4__3"] { background-color: #a8bdd6 } }
		&:nth-child(8n+3) { [data-p~="4__3"] { background-color: #e39d92 } }
		&:nth-child(8n+4) { [data-p~="4__3"] { background-color: #a6888b } }

		&:nth-child(8n+5) { [data-p~="4__3"] { background-color: #a6888b } }
		&:nth-child(8n+6) { [data-p~="4__3"] { background-color: #e39d92 } }
		&:nth-child(8n+7) { [data-p~="4__3"] { background-color: #a8bdd6 } }
		&:nth-child(8n+8) { [data-p~="4__3"] { background-color: #dcc891 } }
	}
}



[data-p~="text_1__1"] {
	@include d("ft-fy=2nd-rr");
	@include d("co=7th");
	@include d("ft-se=30px");
	@include d("lr-sg=1px");
	@include d("le-ht=1");

	@include d("@1600=ft-se=24px");
	@include d("@1000=ft-se=18px");
}






