@media (max-width: 1920px) {
    [data-d~="@1920=ht=30px"] { height: 30px }

    [data-d~="@1920=pg=20px"] { padding: 20px }

    [data-d~="@1920=mn-tp=10px"] { margin-top: 10px }

}



@media (max-width: 1600px) {
    [data-g~="@1600=36"] { width: calc(100 / (36 / 36) * 1%) }
    [data-g~="@1600=34"] { width: calc(100 / (36 / 34) * 1%) }
    [data-g~="@1600=30"] { width: calc(100 / (36 / 30) * 1%) }
    [data-g~="@1600=24"] { width: calc(100 / (36 / 24) * 1%) }



    [data-d~="@1600=ht=20px"] { height: 20px }
    [data-d~="@1600=ht=40px"] { height: 40px }

    [data-d~="@1600=ft-se=12px"] { font-size: 12px }
    [data-d~="@1600=ft-se=14px"] { font-size: 14px }
    [data-d~="@1600=ft-se=16px"] { font-size: 16px }
    [data-d~="@1600=ft-se=20px"] { font-size: 20px }
    [data-d~="@1600=ft-se=24px"] { font-size: 24px }
    [data-d~="@1600=ft-se=26px"] { font-size: 26px }
    [data-d~="@1600=ft-se=30px"] { font-size: 30px }

    [data-d~="@1600=pg=10px"] { padding: 10px }
    [data-d~="@1600=pg=010px"] { padding: 0 10px }

    [data-d~="@1600=mn-tp=10px"] { margin-top: 10px }
    [data-d~="@1600=mn-tp=20px"] { margin-top: 20px }

    [data-d~="@1600=mn-lt=10px"] { margin-left: 10px }
    [data-d~="@1600=mn-lt=20px"] { margin-left: 20px }
    [data-d~="@1600=mn-lt=40px"] { margin-left: 40px }

}



@media (max-width: 1450px) {
    [data-g~="@1450=34"] { width: calc(100 / (36 / 34) * 1%) }
    [data-g~="@1450=28"] { width: calc(100 / (36 / 28) * 1%) }
    [data-g~="@1450=26"] { width: calc(100 / (36 / 26) * 1%) }
    [data-g~="@1450=18"] { width: calc(100 / (36 / 18) * 1%) }
    [data-g~="@1450=9"] { width: calc(100 / (36 / 9) * 1%) }

    [data-g~="@1450=>12"] > * { width: calc(100 / (36 / 12) * 1%) }


    [data-d~="@1450=dy=ne"] { display: none }


    [data-d~="@1450=ft-se=22px"] { font-size: 22px }

    [data-d~="@1450=rt=0"] { right: 0 }

    [data-d~="@1450=lt=0"] { left: 0 }

}



@media (max-width: 1000px) {
    [data-g~="@1000=36"] { width: calc(100 / (36 / 36) * 1%) }

    [data-g~="@1000=>18"] > * { width: calc(100 / (36 / 18) * 1%) }


    [data-d~="@1000=wh=12px"] { width: 12px }
    [data-d~="@1000=wh=40px"] { width: 40px }
    [data-d~="@1000=wh=80px"] { width: 80px }

    [data-d~="@1000=ht=30px"] { height: 30px }
    [data-d~="@1000=ht=40px"] { height: 40px }


    [data-d~="@1000=ft-se=14px"] { font-size: 14px }
    [data-d~="@1000=ft-se=18px"] { font-size: 18px }


    [data-d~="@1000=dy=ne"] { display: none }
    [data-d~="@1000=dy=fx"] { display: flex }

    [data-d~="@1000=bm=40px"] { bottom: 40px }
    [data-d~="@1000=bm=60px"] { bottom: 60px }


    [data-d~="@1000=or=1"] { order: 1 }
    [data-d~="@1000=or=2"] { order: 2 }
    [data-d~="@1000=or=3"] { order: 3 }
    [data-d~="@1000=or=4"] { order: 4 }
    [data-d~="@1000=or=5"] { order: 5 }
    [data-d~="@1000=or=6"] { order: 6 }


    [data-d~="@1000=fx-wp=wp"] { flex-wrap: wrap }

    [data-d~="@1000=jy-ct=cr"] { justify-content: center }

    [data-d~="@1000=oy=.5"] { opacity: .5 }

    [data-d~="@1000=pg=il"] { padding: initial }
    [data-d~="@1000=pg=10px20px"] { padding: 10px 20px }

    [data-d~="@1000=pg-tp=30px"] { padding-top: 30px }
    [data-d~="@1000=pg-tp=40px"] { padding-top: 40px }
    [data-d~="@1000=pg-tp=50%"] { padding-top: 50% }
    [data-d~="@1000=pg-tp=70%"] { padding-top: 70% }

    [data-d~="@1000=pg-bm=30px"] { padding-bottom: 30px }

    [data-d~="@1000=mn-tp=10px"] { margin-top: 10px }
    [data-d~="@1000=mn-tp=20px"] { margin-top: 20px }


    [data-d~="@1000=an-dn=10s"] { animation-duration: 10s }

}



@media (max-width: 600px) {
    [data-g~="@600=36"] { width: calc(100 / (36 / 36) * 1%) }


    [data-d~="@600=ft-se=14px"] { font-size: 14px }
    [data-d~="@600=ft-se=18px"] { font-size: 18px }

    [data-d~="@600=fx-wp=wp"] { flex-wrap: wrap }

    [data-d~="@600=mn-tp=5px"] { margin-top: 5px }
    [data-d~="@600=mn-tp=20px"] { margin-top: 20px }

    [data-d~="@600=mn-lt=il"] { margin-left: initial }

}
