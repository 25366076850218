@if $decoratorsEnabled {
    @each $decorator, $declaration in $decorators {
        @each $property, $value in $declaration {
            [#{$decoratorsAttribute}~="#{$decorator}"] {
                #{$property}: $value;
            }
        }
    }
    @if $decoratorsBreakpoints {
        @each $breakpoint, $resolution in $breakpoints {
            @media (max-width: $resolution) {
                @each $decorator, $declaration in $decorators {
                    @each $property, $value in $declaration {
                        [#{$decoratorsAttribute}~="@#{$breakpoint}=#{$decorator}"] {
                            #{$property}: $value;
                        }
                    }
                }
            }
        }
    }
}





:root {
    --color-1st: #a93357;
    --color-2nd: #e7e9f4;
    --color-3rd: rgba(191, 186, 208, .7);
    --color-4th: #938948;
    --color-5th: #6C7C65;
    --color-6th: #7895A0;
    --color-7th: #221c21;
    --color-8th: #745f60;

    --font-1st-rr: "Montserrat-Regular";
    --font-1st-bd: "Montserrat-Bold";
    --font-2nd-rr: "DynaPuff-Regular";
}





@keyframes pulse_1__1 {
    0% { transform: scale(1) }
	50% { transform: scale(1.2) }
	100% { transform: scale(1) }
}

@keyframes marquee_1__1 {
    0% { left: 100% }
    100% { left: -100% }
}





[data-d~="fl=we"] { fill: white }
[data-d~="fl=bk"] { fill: black }
[data-d~="fl=1st"] { fill: var(--color-1st) }
[data-d~="fl=2nd"] { fill: var(--color-2nd) }
[data-d~="fl=3rd"] { fill: var(--color-3rd) }
[data-d~="fl=4th"] { fill: var(--color-4th) }
[data-d~="fl=5th"] { fill: var(--color-5th) }
[data-d~="fl=6th"] { fill: var(--color-6th) }
[data-d~="fl=7th"] { fill: var(--color-7th) }
[data-d~="fl=8th"] { fill: var(--color-8th) }


[data-d~="se=we"] { stroke: white }
[data-d~="se=bk"] { stroke: black }
[data-d~="se=1st"] { stroke: var(--color-1st) }
[data-d~="se=2nd"] { stroke: var(--color-2nd) }
[data-d~="se=3rd"] { stroke: var(--color-3rd) }
[data-d~="se=4th"] { stroke: var(--color-4th) }
[data-d~="se=5th"] { stroke: var(--color-5th) }
[data-d~="se=6th"] { stroke: var(--color-6th) }
[data-d~="se=7th"] { stroke: var(--color-7th) }
[data-d~="se=8th"] { stroke: var(--color-8th) }


[data-d~="bd-cr=tt"] { background-color: transparent }
[data-d~="bd-cr=we"] { background-color: white }
[data-d~="bd-cr=bk"] { background-color: black }
[data-d~="bd-cr=1st"] { background-color: var(--color-1st) }
[data-d~="bd-cr=2nd"] { background-color: var(--color-2nd) }
[data-d~="bd-cr=3rd"] { background-color: var(--color-3rd) }
[data-d~="bd-cr=4th"] { background-color: var(--color-4th) }
[data-d~="bd-cr=5th"] { background-color: var(--color-5th) }
[data-d~="bd-cr=6th"] { background-color: var(--color-6th) }
[data-d~="bd-cr=7th"] { background-color: var(--color-7th) }
[data-d~="bd-cr=8th"] { background-color: var(--color-8th) }

[data-d~="bd-cr=_1"] { background-color: #dddddd }
[data-d~="bd-cr=_2"] { background-color: rgba(255, 255, 255, .7) }
[data-d~="bd-cr=_3"] { background-color: rgba(255, 255, 255, .5) }
[data-d~="bd-cr=_4"] { background-color: #f2f2f2 }


[data-d~="bd-cr=ensure=1"] { background-color: #dcc891 }
[data-d~="bd-cr=ensure=2"] { background-color: #a8bdd6 }
[data-d~="bd-cr=ensure=3"] { background-color: #e39d92 }
[data-d~="bd-cr=ensure=4"] { background-color: #a6888b }



[data-d~="bd-ie=texture_1__1"] { background-image: url("/resources/textures/Texture_1__1.png") }
[data-d~="bd-ie=texture_2__1"] { background-image: url("/resources/textures/Texture_2__1.png") }
[data-d~="bd-ie=1"] { background-image: url("/resources/T_1__1.webp") }
[data-d~="bd-ie=2"] { background-image: url("/resources/T_2__1.webp") }



[data-d~="br=ne"] { border: none }

[data-d~="br-se=sd"] { border-style: solid }
[data-d~="br-se=dd"] { border-style: dashed }

[data-d~="br-tp-se=sd"] { border-top-style: solid }

[data-d~="br-rt-se=sd"] { border-right-style: solid }

[data-d~="br-bm-se=sd"] { border-bottom-style: solid }

[data-d~="br-lt-se=sd"] { border-left-style: solid }

[data-d~="br-wh=1px"] { border-width: 1px }
[data-d~="br-wh=2px"] { border-width: 2px }
[data-d~="br-wh=3px"] { border-width: 3px }
[data-d~="br-wh=5px"] { border-width: 5px }

[data-d~="br-rs=5px"] { border-radius: 5px }
[data-d~="br-rs=15px"] { border-radius: 15px }
[data-d~="br-rs=100%"] { border-radius: 100% }

[data-d~="br-cr=we"] { border-color: white }
[data-d~="br-cr=bk"] { border-color: black }
[data-d~="br-cr=1st"] { border-color: var(--color-1st) }
[data-d~="br-cr=2nd"] { border-color: var(--color-2nd) }
[data-d~="br-cr=3rd"] { border-color: var(--color-3rd) }
[data-d~="br-cr=4th"] { border-color: var(--color-4th) }
[data-d~="br-cr=5th"] { border-color: var(--color-5th) }
[data-d~="br-cr=6th"] { border-color: var(--color-6th) }
[data-d~="br-cr=7th"] { border-color: var(--color-7th) }
[data-d~="br-cr=8th"] { border-color: var(--color-8th) }

[data-d~="br-cr=_1"] { border-color: #cccccc }



[data-d~="bd-rt=no-rt"] { background-repeat: no-repeat }

[data-d~="bd-se=cr"] { background-size: cover }

[data-d~="bd-pn=cr"] { background-position: center }
[data-d~="bd-pn-x=rt"] { background-position-x: right }
[data-d~="bd-pn-x=lt"] { background-position-x: left }

[data-d~="bd-at=fd"] { background-attachment: fixed }



[data-d~="bx-sw=1"] { box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1) }
[data-d~="bx-sw=2"] { box-shadow: 0 1vw 1vw -1vw rgba(0, 0, 0, .125); }





[data-d~="wh=25%"] { width: 25% }
[data-d~="wh=30%"] { width: 30% }
[data-d~="wh=50%"] { width: 50% }
[data-d~="wh=70%"] { width: 70% }
[data-d~="wh=80%"] { width: 80% }
[data-d~="wh=94%"] { width: 94% }
[data-d~="wh=100%"] { width: 100% }

[data-d~="wh=5px"] { width: 5px }
[data-d~="wh=8px"] { width: 8px }
[data-d~="wh=10px"] { width: 10px }
[data-d~="wh=12px"] { width: 12px }
[data-d~="wh=14px"] { width: 14px }
[data-d~="wh=15px"] { width: 15px }
[data-d~="wh=16px"] { width: 16px }
[data-d~="wh=20px"] { width: 20px }
[data-d~="wh=30px"] { width: 30px }
[data-d~="wh=35px"] { width: 35px }
[data-d~="wh=40px"] { width: 40px }
[data-d~="wh=50px"] { width: 50px }
[data-d~="wh=60px"] { width: 60px }
[data-d~="wh=80px"] { width: 80px }
[data-d~="wh=100px"] { width: 100px }
[data-d~="wh=135px"] { width: 135px }
[data-d~="wh=150px"] { width: 150px }
[data-d~="wh=175px"] { width: 175px }
[data-d~="wh=250px"] { width: 250px }
[data-d~="wh=30%!"] { width: 30% !important }
[data-d~="wh=50%"] { width: 50% }
[data-d~="wh=100%"] { width: 100% }
[data-d~="wh=100%!"] { width: 100% !important }

[data-d~="mn-wh=30px"] { min-width: 30px }
[data-d~="mn-wh=80px"] { min-width: 80px }
[data-d~="mn-wh=100px"] { min-width: 100px }
[data-d~="mn-wh=120px"] { min-width: 120px }
[data-d~="mn-wh=150px"] { min-width: 150px }

[data-d~="mx-wh=120px"] { max-width: 120px }


[data-d~="ht=1px"] { height: 1px }
[data-d~="ht=2px"] { height: 2px }
[data-d~="ht=3px"] { height: 3px }
[data-d~="ht=5px"] { height: 5px }
[data-d~="ht=10px"] { height: 10px }
[data-d~="ht=20px"] { height: 20px }
[data-d~="ht=30px"] { height: 30px }
[data-d~="ht=35px"] { height: 35px }
[data-d~="ht=40px"] { height: 40px }
[data-d~="ht=50px"] { height: 50px }
[data-d~="ht=60px"] { height: 60px }
[data-d~="ht=100px"] { height: 100px }
[data-d~="ht=112px"] { height: 112px }
[data-d~="ht=150px"] { height: 150px }
[data-d~="ht=270px"] { height: 270px }
[data-d~="ht=25vw"] { height: 25vw }
[data-d~="ht=100%"] { height: 100% }

[data-d~="mn-ht=25px"] { min-height: 25px }
[data-d~="mn-ht=30px"] { min-height: 30px }
[data-d~="mn-ht=35px"] { min-height: 35px }
[data-d~="mn-ht=40px"] { min-height: 40px }
[data-d~="mn-ht=60px"] { min-height: 60px }
[data-d~="mn-ht=80px"] { min-height: 80px }
[data-d~="mn-ht=15vw"] { min-height: 15vw }
[data-d~="mn-ht=20vw"] { min-height: 20vw }
[data-d~="mn-ht=25vw"] { min-height: 25vw }
[data-d~="mn-ht=30vw"] { min-height: 30vw }



[data-d~="pn=re"] { position: relative }
[data-d~="pn=ae"] { position: absolute }
[data-d~="pn=sy"] { position: sticky }
[data-d~="pn=fd"] { position: fixed }




[data-d~="tp=0"] { top: 0 }
[data-d~="tp=5px"] { top: 5px }
[data-d~="tp=10px"] { top: 10px }
[data-d~="tp=130px"] { top: 130px }
[data-d~="tp=150px"] { top: 150px }
[data-d~="tp=155px"] { top: 155px }

[data-d~="rt=0"] { right: 0 }
[data-d~="rt=-30px"] { right: -30px }
[data-d~="rt=-40px"] { right: -40px }
[data-d~="rt=5px"] { right: 5px }
[data-d~="rt=40px"] { right: 40px }
[data-d~="rt=60px"] { right: 60px }

[data-d~="bm=0"] { bottom: 0 }
[data-d~="bm=20px"] { bottom: 20px }
[data-d~="bm=40px"] { bottom: 40px }
[data-d~="bm=60px"] { bottom: 60px }
[data-d~="bm=80px"] { bottom: 80px }
[data-d~="bm=100px"] { bottom: 100px }

[data-d~="lt=0"] { left: 0 }
[data-d~="lt=-40px"] { left: -40px }
[data-d~="lt=5px"] { left: 5px }





[data-d~="ft-fy=default"] { font-family: var(--font-1st-rr) }

[data-d~="ft-fy=1st-rr"] { font-family: var(--font-1st-rr) }
[data-d~="ft-fy=1st-bd"] { font-family: var(--font-1st-bd) }

[data-d~="ft-fy=2nd-rr"] { font-family: var(--font-2nd-rr) }



[data-d~="ft-se=8px"] { font-size: 8px }
[data-d~="ft-se=10px"] { font-size: 10px }
[data-d~="ft-se=11px"] { font-size: 11px }
[data-d~="ft-se=12px"] { font-size: 12px }
[data-d~="ft-se=14px"] { font-size: 14px }
[data-d~="ft-se=15px"] { font-size: 15px }
[data-d~="ft-se=16px"] { font-size: 16px }
[data-d~="ft-se=18px"] { font-size: 18px }
[data-d~="ft-se=20px"] { font-size: 20px }
[data-d~="ft-se=22px"] { font-size: 22px }
[data-d~="ft-se=24px"] { font-size: 24px }
[data-d~="ft-se=26px"] { font-size: 26px }
[data-d~="ft-se=28px"] { font-size: 28px }
[data-d~="ft-se=30px"] { font-size: 30px }
[data-d~="ft-se=40px"] { font-size: 40px }
[data-d~="ft-se=50px"] { font-size: 50px }
[data-d~="ft-se=70px"] { font-size: 70px }


[data-d~="lr-sg=1px"] { letter-spacing: 1px }
[data-d~="lr-sg=3px"] { letter-spacing: 3px }
[data-d~="lr-sg=5px"] { letter-spacing: 5px }


[data-d~="co=we"] { color: white }
[data-d~="co=bk"] { color: black }
[data-d~="co=rd"] { color: red }
[data-d~="co=1st"] { color: var(--color-1st) }
[data-d~="co=2nd"] { color: var(--color-2nd) }
[data-d~="co=3rd"] { color: var(--color-3rd) }
[data-d~="co=4th"] { color: var(--color-4th) }
[data-d~="co=5th"] { color: var(--color-5th) }
[data-d~="co=6th"] { color: var(--color-6th) }
[data-d~="co=7th"] { color: var(--color-7th) }
[data-d~="co=8th"] { color: var(--color-8th) }

[data-d~="co=_1"] { color: #cccccc }


[data-d~="le-ht=.8"] { line-height: .8 }
[data-d~="le-ht=1"] { line-height: 1 }
[data-d~="le-ht=1.2"] { line-height: 1.2 }
[data-d~="le-ht=1.4"] { line-height: 1.4 }


[data-d~="ft-wt=nl"] { font-weight: normal }
[data-d~="ft-wt=bd"] { font-weight: bold }

[data-d~="tt-an=lt"] { text-align: left }
[data-d~="tt-an=cr"] { text-align: center }
[data-d~="tt-an=rt"] { text-align: right }

[data-d~="ft-wt=100"] { font-weight: 100 }
[data-d~="ft-wt=200"] { font-weight: 200 }
[data-d~="ft-wt=300"] { font-weight: 300 }
[data-d~="ft-wt=400"] { font-weight: 400 }
[data-d~="ft-wt=500"] { font-weight: 500 }
[data-d~="ft-wt=600"] { font-weight: 600 }
[data-d~="ft-wt=700"] { font-weight: 700 }
[data-d~="ft-wt=800"] { font-weight: 800 }
[data-d~="ft-wt=900"] { font-weight: 900 }


[data-d~="ft-se=ic"] { font-style: italic }

[data-d~="tt-tm=ue"] { text-transform: uppercase }

[data-d~="tt-dn=ue"] { text-decoration: underline }

[data-d~="we-se=nowp"] { white-space: nowrap }





[data-d~="z-ix=header"] { z-index: 9800; }
[data-d~="z-ix=100"] { z-index: 100 }
[data-d~="z-ix=1000"] { z-index: 1000 }
[data-d~="z-ix=2000"] { z-index: 2000 }



[data-d~="vy=hn"] { visibility: hidden }

[data-d~="cu=pr"] { cursor: pointer }

[data-d~="ow=hn"] { overflow: hidden }

[data-d~="ow-y=ao"] { overflow-y: auto }

[data-d~="dy=ne"] { display: none }
[data-d~="dy=ie-bk"] { display: inline-block }
[data-d~="dy=bk"] { display: block }

[data-d~="bx-sg=br-bx"] { box-sizing: border-box }

[data-d~="ur-st=ne"] { user-select: none; }

[data-d~="pr-es=ne"] { pointer-events: none; }

[data-d~="oy=0"] { opacity: 0 }

[data-d~="ot-ft=cr"] { object-fit: cover }

[data-d~="ot-pn=lt_bm"] { object-position: left bottom }

[data-d~="lt-se-te=ne"] { list-style-type: none }

[data-d~="oe=ne"] { outline: none }

[data-d~="ie-rg=-wt-oe-ct"] { image-rendering: -webkit-optimize-contrast }





[data-d~="pg=il"] { padding: initial }
[data-d~="pg=1px"] { padding: 1px }
[data-d~="pg=2px"] { padding: 2px }
[data-d~="pg=3px"] { padding: 3px }
[data-d~="pg=4px"] { padding: 4px }
[data-d~="pg=5px"] { padding: 5px }
[data-d~="pg=10px"] { padding: 10px }
[data-d~="pg=20px"] { padding: 20px }
[data-d~="pg=30px"] { padding: 30px }
[data-d~="pg=010px"] { padding: 0 10px }
[data-d~="pg=5px10px"] { padding: 5px 10px }
[data-d~="pg=5px20px"] { padding: 5px 20px }
[data-d~="pg=10px20px"] { padding: 10px 20px }
[data-d~="pg=10px40px"] { padding: 10px 40px }
[data-d~="pg=020px"] { padding: 0 20px }

[data-d~="pg-tp=il"] { padding-top: initial }
[data-d~="pg-tp=il!"] { padding-top: initial !important }
[data-d~="pg-tp=5px"] { padding-top: 5px }
[data-d~="pg-tp=10px"] { padding-top: 10px }
[data-d~="pg-tp=20px"] { padding-top: 20px }
[data-d~="pg-tp=30px"] { padding-top: 30px }
[data-d~="pg-tp=40px"] { padding-top: 40px }
[data-d~="pg-tp=60px"] { padding-top: 60px }
[data-d~="pg-tp=30%"] { padding-top: 30% }
[data-d~="pg-tp=35%"] { padding-top: 35% }
[data-d~="pg-tp=40%"] { padding-top: 40% }
[data-d~="pg-tp=50%"] { padding-top: 50% }
[data-d~="pg-tp=60%"] { padding-top: 60% }
[data-d~="pg-tp=150%"] { padding-top: 150% }

[data-d~="pg-bm=il"] { padding-bottom: initial }
[data-d~="pg-bm=il!"] { padding-bottom: initial !important }
[data-d~="pg-bm=10px"] { padding-bottom: 10px }
[data-d~="pg-bm=20px"] { padding-bottom: 20px }
[data-d~="pg-bm=40px"] { padding-bottom: 40px }

[data-d~="pg-lt=10px"] { padding-left: 10px }
[data-d~="pg-lt=20px"] { padding-left: 20px }
[data-d~="pg-lt=30px"] { padding-left: 30px }





[data-d~="mn=il"] { margin: initial }
[data-d~="mn=il!"] { margin: initial !important }

[data-d~="mn-tp=il!"] { margin-top: initial !important }
[data-d~="mn-tp=-40px"] { margin-top: -40px }
[data-d~="mn-tp=1px"] { margin-top: 1px }
[data-d~="mn-tp=2px"] { margin-top: 2px }
[data-d~="mn-tp=5px"] { margin-top: 5px }
[data-d~="mn-tp=8px"] { margin-top: 8px }
[data-d~="mn-tp=10px"] { margin-top: 10px }
[data-d~="mn-tp=20px"] { margin-top: 20px }
[data-d~="mn-tp=30px"] { margin-top: 30px }
[data-d~="mn-tp=35px"] { margin-top: 35px }
[data-d~="mn-tp=40px"] { margin-top: 40px }
[data-d~="mn-tp=60px"] { margin-top: 60px }
[data-d~="mn-tp=100px"] { margin-top: 100px }

[data-d~="mn-rt=5px"] { margin-right: 5px }

[data-d~="mn-bm=il!"] { margin-bottom: initial !important }
[data-d~="mn-bm=5px"] { margin-bottom: 5px }
[data-d~="mn-bm=10px"] { margin-bottom: 10px }
[data-d~="mn-bm=20px"] { margin-bottom: 20px }
[data-d~="mn-bm=35px"] { margin-bottom: 35px }
[data-d~="mn-bm=40px"] { margin-bottom: 40px }

[data-d~="mn-lt=-20px"] { margin-left: -20px }
[data-d~="mn-lt=5px"] { margin-left: 5px }
[data-d~="mn-lt=10px"] { margin-left: 10px }
[data-d~="mn-lt=20px"] { margin-left: 20px }
[data-d~="mn-lt=40px"] { margin-left: 40px }
[data-d~="mn-lt=60px"] { margin-left: 60px }
[data-d~="mn-lt=100px"] { margin-left: 100px }





[data-d~="tn=250ms"] { transition: 200ms }

[data-d~="tn-dn=250ms"] { transition-duration: 250ms }

[data-d~="tm=re(45deg)"] { transform: rotate(45deg) }





[data-d~="an-in-ct=ie"] { animation-iteration-count: infinite }
[data-d~="an-tg-fn=ee-in-ot"] { animation-timing-function: ease-in-out }
[data-d~="an-fl-me=fs"] { animation-fill-mode: forwards }
[data-d~="an-py-se=pd"] { animation-play-state: paused }
[data-d~="an-tg-fn=lr"] { animation-timing-function: linear }

[data-d~="an-dn=2000ms"] { animation-duration: 2000ms }
[data-d~="an-dn=30s"] { animation-duration: 30s }

[data-d~="an-ne=1"] { animation-name: rotate_1__1 }
[data-d~="an-ne=2"] { animation-name: pulse_1__1 }
[data-d~="an-ne=3"] { animation-name: marquee_1__1 }











[data-d~=":hr->wh"]:hover {
    [data-d~=":hr->wh=100%"] { width: 100% }
}

[data-d~=":hr->co"]:hover {
    [data-d~=":hr->co=we"] { color: white }
    [data-d~=":hr->co=bk"] { color: black }
}

[data-d~=":hr->fl"]:hover {
    [data-d~=":hr->fl=we"] { fill: white }
    [data-d~=":hr->fl=bk"] { fill: black }
}

[data-d~=":hr=>tm=se()"]:hover {
    [data-d~=":hr=>tm=se(1.2)"] { transform: scale(1.2) }
}



[data-d~=":hr=bd-cr=1st"]:hover { background-color: var(--color-1st) }

[data-d~=":hr=tt-dn=ue"]:hover { text-decoration: underline }

[data-d~=":hr=an-py-se=pd"]:hover { animation-play-state: paused }

[data-d~=":dd=bd-cr=_1"]:disabled { background-color: #cccccc }
[data-d~=":dd=bd-oy=.2"]:disabled { opacity: .2 }

[data-d~=":dd=oy=.5"]:disabled { opacity: .5 }













